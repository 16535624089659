import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';
import { CourierResolver, CourierResolverApi, LoopBackConfig, PushNotificationApi, PushSubscription } from '../sdk';
import { CourierService } from './courier.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  isMobile = false;
  showFooter = true;
  shouldRetryConnection = false;
  publicKey = 'BMOPf-pIn8DL2CkimU2wV51PKi6aWnRIXEfIguF76psfIpbAJHQeYnkQRlkNhECZyRoVXamkwK4CyuKUp7K2FJ0';
  constructor(
    private swUpdate: SwUpdate,
    private swPush: SwPush,
    private observer: BreakpointObserver,
    private router: Router,
    private authService: AuthService,
    private pushNotificationApi: PushNotificationApi
  ) {
    LoopBackConfig.setApiVersion(environment.apiVersion);
    LoopBackConfig.setBaseURL(environment.apiUrl);
  }

  init(): void {
    this.checkForUpdate();

    this.observer.observe([Breakpoints.XSmall]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });

    this.swPush.notificationClicks.subscribe(event => {
      console.log('Received notification: ', event);
      const url = event.notification.data.url;
      window.open(url, '_blank');
    });
  }

  checkForUpdate(): void {
    console.log('Checking for updates');
    this.swUpdate.available.subscribe(event => {
      // this.showUpdateAvailable();
      this.update();
    });
  }

  showUpdateAvailable(): void {
    const confirmation = confirm('A system update is available, do you want to update now');
    if (confirmation) {
      this.update();
    }
  }

  update(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  registerForPush(): void {
    console.log('Registering for push');
    if (!this.swPush.isEnabled) {
      console.log('Notification is not enabled');
      return;
    }

    this.swPush
      .requestSubscription({
        serverPublicKey: this.publicKey
      })
      .then(subscription => {
        console.log(subscription);
        const jsonSubscription = subscription.toJSON();
        const sub = new PushSubscription({
          courierUserId: this.authService.auth.getCurrentUserId(),
          // tslint:disable-next-line: radix
          courierId: Number.parseInt(localStorage.getItem('OX_COURIER_ID')),
          endpoint: subscription.endpoint,
          keys: jsonSubscription.keys
        });
        this.pushNotificationApi.subscribe(sub).subscribe(
          success => {
            console.log('Registration successful', success);
          },
          error => {
            console.error('Failed to send push to server', error);
          }
        );
      })
      .catch(err => console.log(err));
  }
}
