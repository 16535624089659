<div id="search-container">
  <input
    (input)="onFilterChange($event.target.value)"
    type="text"
    oxInput
    [placeholder]="searchPlaceholder"
  />
  <div *ngIf="systemService.isMobile" class="filter-content">
      <p (click)="filterBy(status)"  [class.active]="status === searchFilter" *ngFor="let status of status" class="filter-chip">{{status}}</p>
  </div>
</div>
<div class="package-grid">
  <ng-content>

  </ng-content>
</div>
