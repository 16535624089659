<nav *ngIf="courier$ | async as courier" class="navbar">
  <ul class="navbar-nav">
    <li class="nav-item logo">
      <a class="nav-link">
        <img class="nav-image" [src]="courier.imageUrl" [alt]="courier.name" />
        <span class="link-text">{{ courier.name }}</span>
      </a>
    </li>
    <ng-container *ngIf="systemService.isMobile; else desktopMenuTemplate">
      <li
        class="nav-item"
        [routerLinkActiveOptions]="{ exact: true }"
        routerLinkActive="active"
        [routerLink]="item.link"
        *ngFor="let item of mobileMenu"
      >
        <a class="nav-link">
          <img class="nav-image" [src]="item.icon" [alt]="item.name" />
          <span class="link-text">{{ item.name }}</span>
        </a>
      </li>
    </ng-container>
    <ng-template #desktopMenuTemplate>
      <li class="nav-item" routerLinkActive="active" [routerLink]="item.link" *ngFor="let item of desktopMenu">
        <a class="nav-link">
          <img class="nav-image" [src]="item.icon" [alt]="item.name" />
          <span class="link-text">{{ item.name }}</span>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a [href]="externalUrl" target="_blank" class="nav-link">
          <img class="nav-image" src="https://ik.imagekit.io/47zhisylvftkf/icon_xJUf2eUS8.png" />
          <span class="link-text">Info</span>
        </a>
      </li>
    </ng-template>
  </ul>
</nav>
