import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SystemService } from './system.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CourierService } from './courier.service';
import { environment } from 'src/environments/environment';

interface LoopbackFileResult {
  container: string;
  field: string;
  name: string;
  originalFilename: string;
  size: number;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(private http: HttpClient, private courierService: CourierService) {}

  uploadFiles(files: File[], folder: string): Observable<LoopbackFileResult[]> {
    return this.courierService.courier$.pipe(
      switchMap(courier => {
        const endpoint = `${environment.apiUrl}/${environment.apiVersion}/Uploads/${folder}-${courier.id}/upload`;
        const formData: FormData = new FormData();
        files.forEach(file => {
          formData.append('file', file);
        });
        return this.http.post(endpoint, formData, {}).pipe(
          map((uploadResult: any) => {
            return uploadResult.result.files.file;
          })
        );
      })
    );
  }

  uploadFile(file: File, folder: string): Observable<LoopbackFileResult> {
    return this.courierService.courier$.pipe(
      switchMap(courier => {
        const endpoint = `${environment.apiUrl}/${environment.apiVersion}/Uploads/${folder}-${courier.id}/upload`;
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post(endpoint, formData, {}).pipe(
          map((uploadResult: any) => {
            return uploadResult.result.files.file[0];
          })
        );
      })
    );
  }
}
