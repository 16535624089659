import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CourierUser } from 'src/app/shared/sdk';
import { SystemService } from '../../shared/services/system.service';
import { AppbarService } from '../../shared/services/appbar.service';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';
import { PackageService } from 'src/app/packages/package.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'ox-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.scss']
})
export class AppbarComponent implements OnInit {
  user$: Observable<CourierUser>;
  constructor(
    private authService: AuthService,
    public systemService: SystemService,
    public appbarService: AppbarService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private packageService: PackageService
  ) { }

  ngOnInit(): void {
    this.user$ = this.authService.currentUser;

    this.router.events
          .pipe(filter(event => event instanceof NavigationEnd),
            map(() => {
              let route = this.route.firstChild;
              let child = route;
              while (child) {
                if (child.firstChild) {
                  child = child.firstChild;
                  route = child;
                } else {
                  child = null;
                }
              }
              return route;
            }),
            mergeMap(route => route.data)
          )
          .subscribe(data => {
            this.appbarService.pageTitle = data.title;
            const path = this.location.path(true);
            if (!path.includes('/main/packages')) {
              this.packageService.resetFilters();
            }
          });
  }

  back(): void {
    const path = this.location.path(true);
    if (path === '/main/packages') {
      this.packageService.resetFilters();
      return;
    }

    if (path.includes('/main/packages/')) {
      this.router.navigate(['/main/packages']);
      return;
    }

    if (path.includes('/main/invoices/')) {
      this.router.navigate(['/main/invoices']);
      return;
    }

    if (path.includes('/main/more/profile/update')) {
      this.router.navigate(['/main/more/profile']);
      return;
    }

    if (path.includes('/main/more/profile')) {
      if (this.systemService.isMobile) {
      this.router.navigate(['/main/more']);
      } else {
        this.router.navigate(['/main'])
      }
      return;
    }

    if (path.includes('/main/more/')) {
      if (this.systemService.isMobile) {
        this.router.navigate(['/main/more']);
        } else {
          this.router.navigate(['/main'])
        }
        return;
    }

    this.location.back();
  }

}
