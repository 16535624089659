import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CourierPackage, CourierPackageApi } from '../shared/sdk';
import { AppbarService } from '../shared/services/appbar.service';
import { CourierService } from '../shared/services/courier.service';

export interface PackageEstimate {
  total: number;
  processingFee: number;
  freightCharge: number;
  customsFee: number;
}

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  package: CourierPackage;
  isQuerySet = false;
  filterValue = '';
  statusList = ['Pre Alert', 'At Warehouse', 'In Transit', 'Ready', 'Delivered'];
  constructor(
    private authService: AuthService,
    private packageApi: CourierPackageApi,
    private appbarService: AppbarService,
    private route: ActivatedRoute,
    private router: Router,
    private courierService: CourierService,
    private toast: ToastrService
  ) {}

  public filterBy(status: string): void {
    if (status === this.filterValue) {
      this.filterValue = '';
    } else {
      this.appbarService.showBackButton = true;
      this.filterValue = status;
      this.isQuerySet = true;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        packageFilterBy: this.filterValue
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true
    });
  }

  public resetFilters(): void {
    this.filterValue = '';
    this.isQuerySet = false;
    this.appbarService.showBackButton = false;
  }

  getPackageCount(): Observable<{
    preAlertCount: number;
    atWarehouseCount: number;
    inTransitCount: number;
    readyCount: number;
    deliveredCount: number;
  }> {
    return this.packageApi
      .find({
        where: {
          ownerId: this.authService.auth.getCurrentUserId()
        }
      })
      .pipe(
        map((courierPackages: CourierPackage[]) => {
          return {
            preAlertCount: courierPackages.filter(cPackage => cPackage.status === 'Pre Alert').length,
            atWarehouseCount: courierPackages.filter(cPackage => cPackage.status === 'At Warehouse').length,
            inTransitCount: courierPackages.filter(cPackage => cPackage.status === 'In Transit').length,
            readyCount: courierPackages.filter(cPackage => cPackage.status === 'Ready').length,
            deliveredCount: courierPackages.filter(cPackage => cPackage.status === 'Delivered').length
          };
        })
      );
  }

  createPackage(cPackage: Partial<CourierPackage>): Observable<Partial<CourierPackage>> {
    return this.packageApi.create({ ...cPackage, source: 'User' });
  }

  getPackages(status?: string, includeInvoice = false): Observable<CourierPackage[]> {
    const include = includeInvoice ? ['invoice'] : [];

    return this.packageApi.find({
      where: {
        ownerId: this.authService.auth.getCurrentUserId(),
        status: status || undefined
      },
      include,
      order: 'lastUpdatedOn DESC'
    });
  }

  getPackageById(id: number): Observable<CourierPackage> {
    return this.packageApi.findById(id);
  }

  estimatePackagePrice(weight: number, price: number, estimateCustom): PackageEstimate {
    const courier = this.courierService.courier;
    const useJmd = courier.courierSetting.useJmd;
    const exchangeRate = courier.courierSetting.exchangeRate;
    const weightPrice = courier.courierSetting.weightPrice;
    const pFee1 = courier.courierSetting.processingFee1;
    const pFee2 = courier.courierSetting.processingFee2;
    const useFixedCustomRate = courier.courierSetting.useFixedCustomRate;
    const fixedCustomRate = courier.courierSetting.fixedCustomRate;

    if (weight > weightPrice.length) {
      this.toast.info(
        `That is a very heavy package, please contact us at ${courier.email} for a customized quotation.`
      );
    }

    const packageEstimate: PackageEstimate = {
      processingFee: Math.round((weight <= 100 ? pFee1 : pFee2) * (useJmd ? 1 : exchangeRate)) || 0,
      customsFee: Math.round((price > 100 ? price * fixedCustomRate : 0) * exchangeRate) || 0,
      freightCharge: Math.round(weightPrice[weight - 1] * (useJmd ? 1 : exchangeRate)) || 0,
      total: 0
    };

    packageEstimate.total = Math.round(
      packageEstimate.processingFee + packageEstimate.customsFee + packageEstimate.freightCharge
    );

    return packageEstimate;
  }

  updatePackage(cPackage: CourierPackage): Observable<CourierPackage> {
    return this.packageApi.patchAttributes(cPackage.id, cPackage);
  }

  deletePackage(id: number): Observable<void> {
    return this.packageApi.deleteById(id);
  }
}
