import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Courier, LoopBackConfig } from './shared/sdk';
import { CourierService } from './shared/services/courier.service';
import { SystemService } from './shared/services/system.service';

@Component({
  selector: 'ox-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private systemService: SystemService,
    private courierService: CourierService,
  ) {
  }

  ngOnInit() {
    this.systemService.init();
    this.courierService.init();
  }
}
