import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppbarService {
  private _showBackButton = false;
  private _pageTitle = 'Packages';
  constructor() { }

  set pageTitle(value) {
    setTimeout(() => {
      this._pageTitle = value;
    }, 0);
  }

  get pageTitle(): string {
    return this._pageTitle;
  }

  set showBackButton(value) {
    setTimeout(() => {
      this._showBackButton = value;
    }, 0);
  }

  get showBackButton(): boolean {
    return this._showBackButton;
  }
}
