import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoopBackAuth, SDKToken } from '../sdk';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
  constructor(public auth: LoopBackAuth) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const fileEndpoints = ['/api/Uploads'];

    const isFileEndpoint = fileEndpoints.some(endpoint => req.url.includes(endpoint));

    let clonedRequest: HttpRequest<any>;
    if (isFileEndpoint) {
      clonedRequest = req.clone({
        setHeaders: {
          'x-api-key': '0d7f4b1b-0d7f-4b1b-9d0f-8b0f3f7c6f2a'
          // Authorization: `Bearer ${bearerToken}`
        }
      });
    } else {
      clonedRequest = req.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'x-api-key': '0d7f4b1b-0d7f-4b1b-9d0f-8b0f3f7c6f2a'
          // Authorization: `Bearer ${bearerToken}`
        }
      });
    }

    return next.handle(clonedRequest);
  }
}
