import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PackageEstimate, PackageService } from '../packages/package.service';
import { CourierSettingsApi } from '../shared/sdk';
import { AppbarService } from '../shared/services/appbar.service';
import { CourierService } from '../shared/services/courier.service';
import { ThemeService } from '../shared/services/theme.service';

@Component({
  selector: 'ox-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss']
})
export class CalculatorComponent implements OnInit {
  estimateForm: FormGroup;
  estimate = 0;
  customFee = 0;
  showSecondAction: boolean;
  calculatorType: string;
  packageEstimate: PackageEstimate = {
    customsFee: 0,
    freightCharge: 0,
    processingFee: 0,
    total: 0
  };
  constructor(
    private fb: FormBuilder,
    private appbarService: AppbarService,
    public themeService: ThemeService,
    private toast: ToastrService,
    private courierService: CourierService,
    private packageService: PackageService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.calculatorType = this.courierService.courier.courierSetting.calculatorType
    this.appbarService.pageTitle = 'Calculator';
    this.estimateForm = this.fb.group({
      weight: [1, [Validators.required, Validators.min(1),]],
      estimateCustoms: [false, []],
      value: [1, [Validators.min(1), Validators.required]]
    });

    // console.log(this.router.url);
    if (this.router.url === '/calculator') {
      this.showSecondAction = true;
    } else {
      this.showSecondAction = false;
    }
  }


  calculate(): void {
    const formValues = this.estimateForm.value;
    let estimate = this.packageService.estimatePackagePrice(formValues.weight, formValues.value, formValues.estimateCustoms);
    this.packageEstimate = estimate;
  }

  signup() {
    this.router.navigate(['/auth'], {
      queryParams: {
        signup: 1
      }
    });
  }

  get weight(): AbstractControl {
    return this.estimateForm.controls.weight;
  }

  get estimateCustoms(): AbstractControl {
    return this.estimateForm.controls.estimateCustoms;
  }

  get value(): AbstractControl {
    return this.estimateForm.controls.value;
  }

}
