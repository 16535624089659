import { Component, Input, OnInit } from '@angular/core';
import { InvoiceService } from 'src/app/invoices/invoice.service';
import { PackageService } from 'src/app/packages/package.service';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'ox-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss']
})
export class SearchContainerComponent implements OnInit {
  @Input() searchPlaceholder = 'Search';
  @Input() type: string;
  searchFilter = '';
  status = [];
  constructor(
    public systemService: SystemService,
    public packageService: PackageService,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit(): void {
    this.searchFilter = '';
    this.packageService.filterValue = '';
    this.invoiceService.filter = '';

    if (this.type.toLowerCase() === 'packages') {
      this.status = this.packageService.statusList;
    } else if (this.type.toLowerCase() === 'invoices') {
      this.status = this.invoiceService.statusList;
    }
  }

  filterBy(filter): void {
    if (filter === this.packageService.filterValue || filter === this.invoiceService.filter) {
      this.packageService.filterValue = '';
      this.invoiceService.filter = '';
      this.searchFilter = '';
      return;
    }

    this.searchFilter = filter;
    if (this.type.toLowerCase() === 'packages') {
      this.packageService.filterValue = filter;
    } else if (this.type.toLowerCase() === 'invoices') {
      this.invoiceService.filter = filter;
    }
  }

  onFilterChange(value): void{
    this.filterBy(value);
  }

}
