import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';
import { SDKBrowserModule } from './shared/sdk';
import { ToastrModule } from 'ngx-toastr';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { CourierService } from './shared/services/courier.service';
import { SystemService } from './shared/services/system.service';
import { ThemeService } from './shared/services/theme.service';
import { NgxMaskModule } from 'ngx-mask';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiKeyInterceptor } from './shared/services/interceptor.service';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('custom-service-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    SharedModule,
    SDKBrowserModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 6000,
      preventDuplicates: true,
      positionClass: 'toast-top-right'
    }),
    NgxMaskModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey
    })
  ],
  providers: [
    SystemService,
    CourierService,
    ThemeService,
    RedirectGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
