import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ox-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent implements OnInit {
  @Input() routerLink;
  @Input() itemId;
  @Input() subtitle;
  @Input() title;
  @Input() dateTitle;
  @Input() date;
  @Input() icon;
  @Input() description;
  @Input() subDescription;
  @Input() status;
  constructor() { }

  ngOnInit(): void {
  }

}
