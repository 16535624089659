import { Directive, ElementRef, HostListener, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[oxButton]'
})
export class OxButtonDirective implements OnChanges {
  @Input() status: string;
  @Input() outlined = false;
  @Input() disabled = false;
  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit() {
    this.setButtonColors();
    this.shouldDisableButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.shouldDisableButton();
  }

  shouldDisableButton(): void {
    if (this.disabled) {
      this.disableButton();
    } else {
      this.enableButton();
    }
  }

  disableButton(): void {
    this.setBackgroundColor('#9e9e9e');
    this.elementRef.nativeElement.disabled = true;
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'not-allowed');
  }

  enableButton(): void {
    this.elementRef.nativeElement.disabled = false;
    this.setButtonColors();
    this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'pointer');
  }

  setButtonColors(): void {
    switch (this.status) {
      case 'primary':
        this.setBackgroundColor('var(--primary-color)');
        this.setTextColor('var(--primary-text-color)');
        break;
      case 'secondary':
        this.setBackgroundColor('var(--secondary-color)');
        this.setTextColor('var(--secondary-text-color)');
        break;
      case 'warn':
        this.setBackgroundColor('var(--warning-color)');
        this.setTextColor('var(--warning-text-color)');
        break;
      case 'error':
        this.setBackgroundColor('var(--error-color)');
        this.setTextColor('var(--error-text-color)');
        break;
      default:
        this.setBackgroundColor('var(--primary-color)');
        this.setTextColor('var(--primary-text-color)');
        break;
    }

    if (this.outlined) {
      this.setBackgroundColor('transparent');
      this.setTextColor('var(--primary-color)');
      this.renderer.setStyle(this.elementRef.nativeElement, 'boxShadow', 'none');
      this.renderer.setStyle(this.elementRef.nativeElement, 'border', '2px solid var(--primary-color)');
      return;
    }
  }

  setBackgroundColor(color: string): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'backgroundColor', color);
    this.renderer.setStyle(this.elementRef.nativeElement, 'borderColor', color);
  }

  setTextColor(color: string): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', color);
  }
}
