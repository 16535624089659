<mat-card class="package-item" [routerLink]="[routerLink, itemId]">
  <div class="header">
    <p class="tracking-number">
      <small *ngIf="subtitle">{{subtitle}} <br></small>
      {{ (title.length > 13) ? (title | slice:0:13) + '...' : title }}
    </p>
    <p class="date">
      <small>{{dateTitle}}<br></small>
      {{date| date}}</p>
  </div>
  <div class="package-body">
    <img class="icon" [src]="icon" alt="" />
    <div class="details">
      <p *ngIf="description" class="title">{{description}}</p>
      <p *ngIf="subDescription" class="desc">{{ (subDescription.length > 13) ? (subDescription | slice:0:13) + '...' : subDescription }}</p>
    </div>
    <div class="status">
      <span>
        {{status}}
      </span>
    </div>
  </div>
</mat-card>
