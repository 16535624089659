import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'ox-screen-container',
  templateUrl: './screen-container.component.html',
  styleUrls: ['./screen-container.component.scss']
})
export class ScreenContainerComponent implements OnInit {
  @Input() imageUrl;
  @Input() title;
  @Input() subtitle;
  @Input() disclaimer;
  @Input() actionName;
  @Input() secondActionName;
  @Input() hasSecondAction = false;
  @Input() useCard = true;
  @Input() buttonDisabled = false;
  @Input() fullHeight = false;
  @Input() actionStatus = 'secondary';
  @Input() secondActionStatus = 'primary';
  @Input() loading = false;
  @Input() fullWidth = false;
  @Output() actionClicked = new EventEmitter<boolean>();
  @Output() secondActionClicked = new EventEmitter<boolean>();
  constructor(public systemService: SystemService) {}

  ngOnInit(): void {}

  action(isSecond: boolean): void {
    isSecond ? this.secondActionClicked.emit(true) : this.actionClicked.emit(true);
  }
}
