import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { Invoice, InvoiceApi, MailBankApi } from '../shared/sdk';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  filter = '';
  statusList = ['Unpaid', 'Paid'];
  constructor(private authService: AuthService, private invoiceApi: InvoiceApi, private mailbankApi: MailBankApi) {}

  getInvoiceById(id: number, isLocalDeliveryOnly = false): Observable<Invoice> {
    if (!isLocalDeliveryOnly) {
      return this.invoiceApi.findById(id, {
        where: {
          customerId: this.authService.auth.getCurrentUserId()
        },
        include: ['courierPackage'],
        fields: {
          id: true,
          courierPackage: true,
          courierPackageId: true,
          amount: true,
          status: true
        }
      });
    } else {
      return this.invoiceApi.findById(id, {
        where: {
          customerId: this.authService.auth.getCurrentUserId()
        },
        include: ['deliveryRequest'],
        fields: {
          id: true,
          deliveryRequest: true,
          deliveryRequestId: true,
          amount: true,
          status: true
        }
      });
    }
  }

  getInvoiceByPackageId(id: number): Observable<Invoice> {
    return this.invoiceApi.findOne({
      where: {
        customerId: this.authService.auth.getCurrentUserId(),
        courierPackageId: id
      },
      include: ['courierPackage'],
      fields: {
        id: true,
        courierPackage: true,
        courierPackageId: true,
        amount: true,
        status: true
      }
    });
  }

  getInvoices(isDeliveryOnly = false) {
    if (isDeliveryOnly) {
      return this.invoiceApi
        .find({
          where: {
            customerId: this.authService.auth.getCurrentUserId()
          },
          include: ['deliveryRequest'],
          fields: {
            id: true,
            deliveryRequest: true,
            deliveryRequestId: true,
            amount: true,
            created: true,
            status: true
          },
          order: 'id DESC'
        })
        .pipe(
          map((invoices: Invoice[]) => {
            return invoices.filter(invoice => {
              return invoice.deliveryRequest;
            });
          })
        );
    } else {
      return this.invoiceApi
        .find({
          where: {
            customerId: this.authService.auth.getCurrentUserId()
          },
          include: ['courierPackage'],
          fields: {
            id: true,
            courierPackage: true,
            courierPackageId: true,
            amount: true,
            created: true,
            status: true
          },
          order: 'id DESC'
        })
        .pipe(
          map((invoices: Invoice[]) => {
            return invoices.filter(invoice => {
              return invoice.courierPackage && invoice.courierPackage.manifestNumber;
            });
          })
        );
    }
  }

  getBalance() {
    return this.invoiceApi
      .find({
        where: {
          customerId: this.authService.auth.getCurrentUserId(),
          status: 'Unpaid'
        },
        fields: {
          id: true,
          amount: true
        }
      })
      .pipe(
        map((invoices: Invoice[]) => {
          let balance = 0;
          for (const invoice of invoices) {
            balance += invoice.amount;
          }
          return balance;
        })
      );
  }

  sendViaEmail(id) {
    return this.mailbankApi.sendInvoice(id);
  }

  createPaypalOrder() {
    return this.invoiceApi.createPaypalOrder;
  }
}
