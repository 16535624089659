<ox-screen-container
  *ngIf="calculatorType === 'Basic'"
  [imageUrl]="themeService.currentIconPack.calculatorIcon"
  [title]="'Get an Estimate'"
  subtitle="Fill in the form below to get started"
  disclaimer="Disclaimer: Please note that is only an estimate of our shipping weight and does NOT include customs fees."
  actionName="CALCULATE"
  (actionClicked)="calculate()"
  [hasSecondAction]="showSecondAction"
  secondActionName= "GET A FREE ACCOUNT"
  (secondActionClicked)="signup()"
>
  <form [formGroup]="estimateForm">
    <div class="form-group">
      <label>Weight (lbs)</label>
      <input oxInput type="number" [formControl]="weight" />
    </div>
    <div class="form-group">
      <label>Item Value ($)</label>
      <input oxInput type="number" [formControl]="value" />
    </div>
  </form>
  <hr>
  <div class="form-group">
    <label>Your Estimate ($)</label>
    <input readonly oxInput type="number" [(ngModel)]="packageEstimate.total"  />
  </div>
</ox-screen-container>



<ox-screen-container
  *ngIf="calculatorType === 'Advanced'"
  [imageUrl]="themeService.currentIconPack.calculatorIcon"
  title="Get an Estimate"
  subtitle="Fill in the form below to get started"
  disclaimer="Disclaimer: Please note that is only an estimate."
  [hasSecondAction]="showSecondAction"
  secondActionName= "GET A FREE ACCOUNT"
  (secondActionClicked)="signup()"
>
  <ng-container>
    <form [formGroup]="estimateForm">
      <div class="form-group">
        <label>Weight (lbs)</label>
        <input oxInput type="number" [formControl]="weight" />
      </div>
      <div class="form-group">
        <label>Item Value USD ($)</label>
        <input oxInput type="number" [formControl]="value" />
      </div>
      <button [disabled]="estimateForm.invalid" (click)="calculate()" oxButton [status]="'secondary'">CALCULATE</button>
    </form>
    <hr>
    <div class="form-group">
      <label>Processing Fee ($)</label>
      <input readonly oxInput type="number" [(ngModel)]="packageEstimate.processingFee" />
    </div>
    <div class="form-group">
      <label>Freight Charge ($)</label>
      <input readonly oxInput type="number" [(ngModel)]="packageEstimate.freightCharge" />
    </div>
    <div class="form-group">
      <label>Customs Estimate ($)</label>
      <input readonly oxInput type="number" [(ngModel)]="packageEstimate.customsFee" />
    </div>
    <div class="form-group">
      <label>Total Charges ($)</label>
      <input readonly oxInput type="number" [(ngModel)]="packageEstimate.total" />
    </div>
  </ng-container>
</ox-screen-container>
