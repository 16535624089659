import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  AccessTokenInterface,
  Courier,
  CourierUser,
  CourierUserApi,
  CourierUserInterface,
  LoopBackAuth,
  PushSubscription,
  PushSubscriptionApi,
  Referral,
  ReferralApi,
  RewardsAccount,
  SDKToken
} from '../shared/sdk';
import { CourierService } from '../shared/services/courier.service';
import { getCustomHeaders } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  currentUserSubject: BehaviorSubject<CourierUser> = new BehaviorSubject(null);
  currentUser$: Observable<CourierUser> = this.currentUserSubject.asObservable();
  constructor(
    private userApi: CourierUserApi,
    public auth: LoopBackAuth,
    private router: Router,
    private http: HttpClient,
    private courierService: CourierService,
    private pushSubscriptionApi: PushSubscriptionApi,
    private referralApi: ReferralApi
  ) {
    if (localStorage.getItem('token')) {
      const token: SDKToken = JSON.parse(localStorage.getItem('token'));
      this.auth.setToken(token);
      this.auth.save();
      this.setCurrentUser(token, true);
      this.autoLogout(token);
    }
  }

  public getAccessToken(): string {
    const tokenString = localStorage.getItem('token');
    if (!tokenString) return '';

    const token: AccessTokenInterface = JSON.parse(tokenString);
    return token.id || '';
  }

  get currentUser(): Observable<CourierUser> {
    if (!this.courierService.courier) {
      return;
    }

    if (!this.currentUser$ && this.auth.getCurrentUserId()) {
      this.currentUser$ = this.findUserById().pipe(shareReplay(1));
    }
    return this.currentUser$;
  }

  clearUser() {
    this.currentUser$ = null;
  }

  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  findUserById(): Observable<CourierUser> {
    // console.log('Finding user by id');

    if (!this.courierService.courier || !this.auth.getCurrentUserId) {
      return null;
    }

    const realm = this.courierService.courier.courierSetting.prefix;
    const padding = this.courierService.courier.mailboxPaddingLength;
    const useV2AddressFormat = this.courierService.courier.courierSetting.useV2AddressFormat;

    return this.userApi
      .findById(this.auth.getCurrentUserId(), {
        include: [
          {
            relation: 'mailboxes'
          },
          {
            relation: 'rewardsAccount'
          },
          {
            relation: 'storeLocation'
          }
        ]
      })
      .pipe(
        map((user: CourierUser) => {
          user.mailboxes.forEach(mailbox => {
            // if (useV2AddressFormat) {
            //   mailbox.mailboxNumber = `${this.pad(mailbox.mailboxNumber, padding, '0')}`;
            // } else {
            //   mailbox.mailboxNumber = `${realm}-${this.pad(mailbox.mailboxNumber, padding, '0')}`;
            // }
            mailbox.mailboxNumber = this.pad(mailbox.mailboxNumber, padding, '0');
          });
          return {
            ...user
          };
        }),
        shareReplay(1),
        catchError(err => {
          this.logout(true);
          throw err;
        })
      );
  }

  autoLogout(token: SDKToken): void {
    const now = new Date();
    const created = token.created;
    const ttl = token.ttl;
    const logoutDate = new Date();
    logoutDate.setSeconds(logoutDate.getSeconds() + ttl);

    if (now >= logoutDate) {
      // console.log('Logout user');
      this.logout();
    }
  }

  login(email: string, password: string): Observable<any> {
    const realm = this.courierService.courier.realm;
    return this.userApi.login({ email, password, realm });
  }

  signup(email: string, password: string, phone: string): Observable<any> {
    const realm = this.courierService.courier.realm;
    return this.userApi.create({ email, password, realm, phone });
  }

  logout(reload = true): void {
    this.currentUserSubject.next(null);
    localStorage.removeItem('role');
    localStorage.removeItem('token');
    localStorage.removeItem('OX_COURIER_ID');
    // this.userApi.logout().subscribe(
    //   res => {
    // console.log('Logged out');
    this.auth.clear();
    localStorage.clear();
    localStorage.removeItem('role');
    this.router.navigate(['/auth']);
    // if (reload) {
    // location.reload();
    // }
    // },
    // err => {
    // this.auth.clear();
    // localStorage.clear();
    // localStorage.removeItem('role');
    // if (reload) {
    // location.reload();
    // }
    // }
    // );
  }

  setCurrentUser(token: SDKToken, cached = false): void {
    // console.log('Token ', token);
    this.auth.setToken(token);
    this.currentUser$ = this.findUserById();
    localStorage.setItem('token', JSON.stringify(token));
    this.autoLogout(token);

    // this.currentUserSubject.next(token.user);
    // this.auth.save();

    if (cached && !this.currentUser$) {
      // console.log('Cached or no user')
      return;
    }

    this.currentUser$.subscribe(
      user => {
        this.currentUserSubject.next(user);
        this.auth.setUser(user);
        this.auth.save();
      },
      err => {
        // console.log('FAILED');
        console.log(err);
      }
    );
  }

  isAuthenticated(): boolean {
    return this.auth.getCurrentUserId() !== null;
  }

  resetPassword(email: string): Observable<any> {
    if (!this.courierService.courier) {
      return;
    }
    const redirectUrl = this.courierService.courier.hostingUrl;
    return this.userApi.resetPassword({
      email,
      courierId: this.courierService.courier.id,
      redirectUrl,
      realm: this.courierService.courier.realm
    });
  }

  changePassword(password: string, confirmPassword: string, accessToken: string): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const resetEndpoint =
      environment.apiUrl + '/' + environment.apiVersion + '/CourierUsers/reset-password?access_token=' + accessToken;
    return this.http.post(
      resetEndpoint,
      {
        newPassword: password,
        confirmation: confirmPassword
      },
      {
        headers
      }
    );
  }

  referUser(referrerId: number, refereeId: number) {
    const referral: Referral = new Referral({
      referrerId,
      refereeId,
      emailConfirmedOn: null,
      signUpConfirmedOn: new Date(),
      firstShipmentConfirmedOn: null
    });
    this.referralApi.create(referral).subscribe(
      _ => {},
      error => {
        console.log('Failed to created referral');
      }
    );
  }

  patchUserAttributes(user: CourierUserInterface): Observable<any> {
    return this.userApi.patchAttributes(this.auth.getCurrentUserId(), user);
  }

  setPassword(currentPassword, newPassword): Observable<any> {
    return this.userApi.changePassword(currentPassword, newPassword);
  }

  getUserSubscriptions(): Observable<PushSubscription[]> {
    return this.pushSubscriptionApi.find({
      where: {
        courierUserId: this.auth.getCurrentUserId()
      }
    });
  }

  deleteUserSubscriptions(subscriptions: PushSubscription[]) {
    console.log('Deleting subscriptions', subscriptions);
    subscriptions.forEach(subscription => {
      this.pushSubscriptionApi.deleteById(subscription.id).subscribe(
        deleted => {
          console.log(deleted);
          console.log('Deleted');
        },
        error => {
          console.log('Failed to delete subscription', error);
        }
      );
    });
  }

  deleteAccount(): void {
    if (this.auth.getCurrentUserId()) {
      this.userApi.deleteById(this.auth.getCurrentUserId()).subscribe(result => {
        this.logout(true);
      });
    }
  }

  loginAsGuest() {
    const guestUser = new CourierUser();
    guestUser.id = -1;
    guestUser.firstName = 'Guest';
    guestUser.lastName = 'User';
    guestUser.isProfileSet = true;
    guestUser.points = 0;

    const rewardsAccount = new RewardsAccount();
    rewardsAccount.points = 0;

    guestUser.rewardsAccount = rewardsAccount;
    this.auth.setUser(guestUser);
    this.currentUserSubject.next(guestUser);
  }

  sendVerificationEmail(): Observable<any> {
    const realm = this.courierService.courier.realm;
    const userId = this.auth.getCurrentUserId()?.toString();

    if (!userId || !realm) {
      return;
    }
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const accessToken = this.getAccessToken();
    const verifyEndpoint =
      environment.apiUrl + '/' + environment.apiVersion + '/MailBanks/verifyUser?access_token=' + accessToken;
    return this.http.post(
      verifyEndpoint,
      {
        realm,
        userId
      },
      {
        headers
      }
    );
  }
}
