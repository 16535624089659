import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';

import {
  Courier,
  CourierApi,
  CourierResolver,
  CourierResolverApi,
  RewardSettingsApi,
  SDKToken
} from 'src/app/shared/sdk';
import { environment } from 'src/environments/environment';
import { SystemService } from './system.service';
import { ThemeService } from './theme.service';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class CourierService {
  OX_COURIER_KEY = 'OX_COURIER_ID';
  private courierSubject = new BehaviorSubject<Courier>(null);
  courier$ = this.courierSubject.asObservable();

  private zohoTagSet = new BehaviorSubject(false);

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private router: Router,
    private courierApi: CourierApi,
    private courierResolverApi: CourierResolverApi,
    private themeService: ThemeService,
    private rewardSettingApi: RewardSettingsApi
  ) {
    // console.log('[COURIER SERVICE]');
  }

  init(): void {
    // console.log('[COURIER SERVICE] INIT');

    if (localStorage.getItem(this.OX_COURIER_KEY)) {
      // console.log('[COURIER SERVICE] Setting Courier');
      this.setCourier();
      return;
    }

    // console.log('[COURIER SERVICE] Finding Courier');
    this.courierResolverApi
      .findOne({
        where: {
          or: [
            {
              adminUrl: location.host
            },
            {
              customerUrl: location.host
            }
          ]
        }
      })
      .subscribe(
        (courierResolver: CourierResolver) => {
          localStorage.setItem(this.OX_COURIER_KEY, courierResolver.courierId.toString());
          this.setCourier();
        },
        error => {
          this.router.navigate(['/error/500']);
        }
      );
  }

  getRewardSettings() {
    return this.courier$.pipe(
      map((courier: Courier) => {
        return courier.rewardSetting;
      })
    );
  }

  setCourier(): void {
    // console.log('[CourierService] Setting Courier');
    this.courierApi
      .findById(localStorage.getItem(this.OX_COURIER_KEY), {
        include: ['courierSetting', 'themeSetting', 'rewardSetting', 'couponCodes']
      })
      .subscribe(
        (courier: Courier) => {
          // console.log('Courier Set');
          this.courierSubject.next(courier);
          this.setMetaData(courier);

          if (!courier.isActive) {
            this.router.navigate(['/error/404']);
          }
        },
        error => {
          console.log(error);
          localStorage.removeItem(this.OX_COURIER_KEY);
          localStorage.clear();
          this.router.navigate(['/']);
        }
      );
  }

  get courier() {
    return this.courierSubject.getValue();
  }

  setMetaData(courier: Courier): void {
    const favIcon: HTMLLinkElement = document.querySelector('#appIcon');
    // console.log('Setting meta data');
    this.courierSubject.next(courier);

    this.themeService.setTheme(courier.themeSetting);

    this.titleService.setTitle(courier.name);
    this.metaService.addTag({
      name: 'theme-color',
      content: courier.themeSetting.primaryColor
    });
    favIcon.href = courier.imageUrl;
    this.createManifest(courier.id);

    if (courier.courierSetting.googleTrackingCode) {
      this.addAnalyticsTag(courier.courierSetting.googleTrackingCode);
    }

    if (courier.courierSetting.paymentsEnabled) {
      // this.addPayPalScriptTag(courier.courierSetting.paypal.appId)
    }

    if (courier.courierSetting.zohoIq) {
      this.addZohoScriptTag();
    }
  }

  logAction(event: string, data: any): void {
    // console.log('Logging action');
    if (!this.courier || !this.courier.courierSetting.googleTrackingCode) {
      return;
    }

    const token: SDKToken = JSON.parse(localStorage.getItem('token'));
    const userId = token?.userId || null;

    // console.log('Logging action');
    // window.dataLayer.push({
    //   event,
    //   data,
    //   userId
    // });
  }

  createManifest(id: number): void {
    // console.log('Creating manifest');
    const link = document.createElement('link');
    link.href = `${environment.apiUrl}/${environment.apiVersion}/Couriers/getManifestFile?courierId=${id}&host=${location.origin}`;
    link.rel = 'manifest';
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  addAnalyticsTag(measurementId: string): void {
    if (!measurementId) {
      return;
    }
    if (measurementId.startsWith('G-')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
      document.getElementsByTagName('head')[0].appendChild(script);

      const scriptBody = document.createElement('script');
      scriptBody.type = 'text/javascript';
      scriptBody.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${measurementId}');`;
      document.getElementsByTagName('head')[0].appendChild(scriptBody);
    } else if (measurementId.startsWith('GTM-')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtm.js?id=${measurementId}`;
      document.getElementsByTagName('head')[0].appendChild(script);

      const scriptBody = document.createElement('script');
      scriptBody.type = 'text/javascript';
      scriptBody.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${measurementId}');`;
      document.getElementsByTagName('head')[0].appendChild(scriptBody);

      //paste this after opening <body> tag
      //       <!-- Google Tag Manager (noscript) -->
      // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NNVWS65W"
      // height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
      // <!-- End Google Tag Manager (noscript) -->
      const noScript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${measurementId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';
      noScript.appendChild(iframe);
      document.body.prepend(noScript);
    }
  }

  addGAScriptTag(measurementId: string): void {
    // <!-- Google tag (gtag.js) -->
    // <script async src="https://www.googletagmanager.com/gtag/js?id=AW-867534589"></script>
    // <script>
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(){dataLayer.push(arguments);}
    //   gtag('js', new Date());
    //   gtag('config', 'AW-867534589');
    // </script>
    // const script = document.createElement('script');
    // script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    // script.async = true;
    // const scriptBody = document.createElement('script');
    // scriptBody.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${measurementId}');`;
    // document.head.appendChild(script);
    // document.head.appendChild(scriptBody);
  }

  addPayPalScriptTag(clientId) {
    // console.log('Adding script tag')
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&locale=en_US&disable-funding=credit`;
    script.defer = true;
    script.type = 'text/javascript';
    // console.log(script);
    document.head.appendChild(script);
  }

  addZohoScriptTag(
    tagId = '7cc0fcbf972b18922525aa937abbc6e9b02249ff53f543badf1c3f37a6ca7e237d209920ba78b0f6f99fc80c82502996'
  ) {
    // console.log('Adding zoho script tag');
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'zsiqchat';
    script.innerText = `var $zoho=$zoho ||
    {};$zoho.salesiq = $zoho.salesiq ||
    {widgetcode: "${tagId}",
    values:{},
    ready:function(){}
    };
    var d=document;
    s=d.createElement("script");
    s.type="text/javascript";
    s.id="zsiqscript";
    s.defer=true;
    s.src="https://salesiq.zoho.com/widget";
    t=d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s,t);`;
    document.head.appendChild(script);

    setTimeout(() => {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.innerText = `
      @media only screen and (max-width: 600px) {
        .siq_bR {
          bottom: calc(6rem);
          animation: slidein;
          animation-duration: 1s;
        }

        @keyframes slidein {
          from {
            opacity: 0;
            bottom: -3rem;
          }

          to {
            opacity: 1;
            bottom: 6rem;
          }
        }
      }
      `;
      document.head.appendChild(style);
    }, 3000);

    // `
    // <script
    //   type="text/javascript"
    //   id="zsiqchat">

    //   var $zoho=$zoho ||
    //   {};$zoho.salesiq = $zoho.salesiq ||
    //   {widgetcode: "7cc0fcbf972b18922525aa937abbc6e9b02249ff53f543badf1c3f37a6ca7e237d209920ba78b0f6f99fc80c82502996",
    //   values:{},
    //   ready:function(){}
    //   };
    //   var d=document;
    //   s=d.createElement("script");
    //   s.type="text/javascript";
    //   s.id="zsiqscript";
    //   s.defer=true;
    //   s.src="https://salesiq.zoho.com/widget";
    //   t=d.getElementsByTagName("script")[0];
    //   t.parentNode.insertBefore(s,t);

    //   </script>
    //   `
  }
}
