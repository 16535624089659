import { ElementRef, Injectable } from '@angular/core';
import { IconPack, IconPackApi } from '../sdk';
import { ThemeSetting } from '../sdk/models/ThemeSetting';
import { CourierService } from './courier.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  defaultIconPack: IconPack = new IconPack({
    name: "Default",
    packageIcon: "https://ik.imagekit.io/47zhisylvftkf/pre-alert_gXLRthkFlLI.png",
    warehouseIcon: "https://ik.imagekit.io/47zhisylvftkf/warehouse_wsqckjkb4S.png",
    transitIcon: "https://ik.imagekit.io/47zhisylvftkf/in-transit_V2rF7pJuJl.png",
    readyIcon: "https://ik.imagekit.io/47zhisylvftkf/ready_-v-dujJK4eB.png",
    deliveredIcon: "https://ik.imagekit.io/47zhisylvftkf/delivered_5gcxzhfkAu.png",
    mailboxIcon: "https://ik.imagekit.io/47zhisylvftkf/mailbox_eUQTeXsdSq.png",
    calculatorIcon: "https://ik.imagekit.io/47zhisylvftkf/calculator_Kt2c9o_WA_.png",
    invoiceIcon: "https://ik.imagekit.io/47zhisylvftkf/invoice_CQkMHYYuPG.png",
    moreIcon: "https://ik.imagekit.io/47zhisylvftkf/more_7z4uw8vZQF.png",
    profileIcon: "https://ik.imagekit.io/47zhisylvftkf/profile_ml2ozcPYfb.png",
    rewardsIcon: "https://ik.imagekit.io/47zhisylvftkf/icons8-gift-100_A_lLv8g5nq.png",
    notificationIcon: "https://ik.imagekit.io/47zhisylvftkf/icons8-notification-100_9Ku5IsUIC.png",
    contactIcon: "https://ik.imagekit.io/47zhisylvftkf/icons8-phone-100_J7owaDL1L.png",
    reportIcon: "https://ik.imagekit.io/47zhisylvftkf/icons8-error-100_fhDyuNf-S.png",
    id: 1
  })
  currentIconPack: IconPack = this.defaultIconPack;
  constructor(
    private iconPackApi: IconPackApi
  ) {
  }

  setTheme(themeSetting: ThemeSetting): void {
    console.log('Setting theme');
    document.documentElement.style.setProperty(`--primary-color`, themeSetting.primaryColor);
    document.documentElement.style.setProperty(`--primary-text-color`, themeSetting.primaryTextColor);
    document.documentElement.style.setProperty(`--secondary-color`, themeSetting.secondaryColor);
    document.documentElement.style.setProperty(`--secondary-text-color`, themeSetting.secondaryTextColor);
    document.documentElement.style.setProperty(`--error-color`, themeSetting.errorColor);
    document.documentElement.style.setProperty(`--error-text-color`, themeSetting.errorTextColor);
    document.documentElement.style.setProperty(`--warning-color`, themeSetting.warningColor);
    document.documentElement.style.setProperty(`--warning-text-color`, themeSetting.warningTextColor);
    document.documentElement.style.setProperty(`--background-color`, themeSetting.backgroundColor);
    document.documentElement.style.setProperty(`--background-text-color`, themeSetting.backgroundTextColor);
    this.setIconPack(themeSetting.iconPack);
  }

  setIconPack(id) {
    this.iconPackApi.findById(id).subscribe((pack: IconPack) => {
      this.currentIconPack = pack;
    }, error => {
      console.log('Failed to find icon pack', error);
      this.currentIconPack = this.defaultIconPack;
    })
  }
}
