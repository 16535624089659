<nav>
  <button (click)="back()" *ngIf="appbarService.showBackButton && systemService.isMobile" class="back-button" mat-icon-button>
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <h1>{{appbarService.pageTitle}}</h1>
  <div id="actions">
  <ox-welcome *ngIf="!systemService.isMobile"></ox-welcome>
  <div routerLinkActive="active" routerLink="more/profile" *ngIf="user$ | async as user" class="circle-avatar">
    <span *ngIf="user.firstName && user.lastName">{{user.firstName[0]}}{{user.lastName[0]}}</span>
  </div>
  </div>
</nav>
