import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { SystemService } from '../shared/services/system.service';

@Component({
  selector: 'ox-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  sub: Subscription;
  showButton = false;
  hasUserRequiredFields = false;
  constructor(public authService: AuthService, public systemService: SystemService, private router: Router) {}

  ngOnInit(): void {
    this.tick();
    // if (this.authService.currentUser) {
    setTimeout(() => {
      this.sub = this.authService.currentUser.subscribe(
        user => {
          if (!user) {
            localStorage.clear();
            this.authService.logout(true);
          }

          if (
            user &&
            (!user.isProfileSet || !user.firstName || !user.lastName || !user?.contactInformation?.mobilePhone)
          ) {
            this.hasUserRequiredFields = false;
            this.router.navigate(['/main/more/profile/onboarding']);
          } else {
            this.hasUserRequiredFields = true;
          }
        },
        error => {
          this.reset();
        }
      );
    }, 500);
    // }
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  reset() {
    localStorage.clear();
    this.authService.logout();
  }

  tick() {
    setTimeout(() => {
      this.authService.currentUser.subscribe(
        user => {
          if (user) {
            this.showButton = true;
          } else {
            this.authService.logout(true);
          }
        },
        error => {
          this.authService.logout(true);
        }
      );
    }, 1000);
  }
}
