import { AfterViewInit, Directive, ElementRef, OnChanges, Renderer2 } from '@angular/core';
import { SystemService } from '../services/system.service';

@Directive({
  selector: '[oxInput]'
})
export class OxInputDirective implements AfterViewInit {

  constructor(
    private systemService: SystemService,
    private renderer2: Renderer2,
    private elRef: ElementRef
  ) { }

  ngAfterViewInit() {
   if (this.systemService.isMobile) {
    this.renderer2.listen(this.elRef.nativeElement, 'focus', event => {
      this.systemService.showFooter = false;
     });

     this.renderer2.listen(this.elRef.nativeElement, 'blur', event => {
      this.systemService.showFooter = true;
    });
   }

  }
}
