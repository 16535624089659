import { ModuleWithProviders, NgModule } from '@angular/core';

import { ThemeService } from './services/theme.service';
import { CourierService } from './services/courier.service';
import { UploadService } from './services/upload.service';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CommonModule } from '@angular/common';
import { OxButtonDirective } from './directives/ox-button.directive';
import { ScreenContainerComponent } from './components/screen-container/screen-container.component';
import { MatCardModule } from '@angular/material/card';
import { SearchContainerComponent } from './components/search-container/search-container.component';
import { FormsModule } from '@angular/forms';
import { ListItemComponent } from './components/list-item/list-item.component';
import { RouterModule } from '@angular/router';
import { OxInputDirective } from './directives/ox-input.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
const COMPONENTS = [
  WelcomeComponent,
  OxButtonDirective,
  OxInputDirective,
  ScreenContainerComponent,
  SearchContainerComponent,
  ListItemComponent,
  GoogleAutocompleteComponent
];

const SERVICES = [ThemeService, CourierService, UploadService];

const MODULES = [MatCardModule, RouterModule, MatProgressSpinnerModule];

@NgModule({
  imports: [CommonModule, FormsModule, ...MODULES],
  declarations: [...COMPONENTS, OxInputDirective, GoogleAutocompleteComponent],
  exports: [...COMPONENTS]
})
export class SharedModule {}
