import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { CourierUser } from '../../sdk';
import { CourierService } from '../../services/courier.service';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'ox-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public courierService: CourierService,
  ) { }

  ngOnInit(): void {
  }

}
