<main *ngIf="authService.currentUser | async as user; else error" [class.onboarding]="!user.isProfileSet">
  <ox-navbar *ngIf="hasUserRequiredFields && user.isProfileSet && systemService.showFooter"></ox-navbar>
  <div id="container">
    <div id="appbar">
      <ox-appbar *ngIf="user.isProfileSet"></ox-appbar>
    </div>
    <div id="outlet-container"><router-outlet></router-outlet></div>
  </div>
</main>

<ng-template #error>
  <h2 class="text-center">Please wait...</h2>
  <button *ngIf="showButton" (click)="reset()" oxButton>RESET</button>
</ng-template>
