import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Courier } from 'src/app/shared/sdk';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { CourierService } from 'src/app/shared/services/courier.service';
import { SystemService } from 'src/app/shared/services/system.service';
import { Select } from '@ngxs/store';
import { ThemeService } from 'src/app/shared/services/theme.service';

@Component({
  selector: 'ox-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  mobileMenu: NavBarItem[] = [];
  desktopMenu: NavBarItem[] = [];
  courier$: Observable<Courier>;
  externalUrl = 'https://johngordon.co';
  constructor(
    public courierService: CourierService,
    public systemService: SystemService,
    private themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.courier$ = this.courierService.courier$;

    this.courier$.subscribe(courier => {
      if (courier) {
        this.createItems();
        this.externalUrl = courier.courierSetting?.externalUrl;
      }
    });
  }

  createItems(): void {
    this.desktopMenu = [
      {
        name: 'Mailbox',
        link: '/main/mailbox',
        icon: this.themeService.currentIconPack.mailboxIcon
      },
      {
        name: 'Packages',
        link: '/main/packages',
        icon: this.themeService.currentIconPack.packageIcon
      },
      // {
      //   name: 'Deliveries',
      //   link: '/main/delivery-request',
      //   icon: this.themeService.currentIconPack.deliveryIcon
      // },
      {
        name: 'Calculator',
        link: '/main/calculator',
        icon: this.themeService.currentIconPack.calculatorIcon
      },
      {
        name: 'Invoices',
        link: '/main/invoices',
        icon: this.themeService.currentIconPack.invoiceIcon
      },
      {
        name: 'Profile',
        link: '/main/more/profile',
        icon: this.themeService.currentIconPack.profileIcon
      }
      // {
      //   name: 'Info',
      //   link: '/info',
      //   icon: 'https://ik.imagekit.io/47zhisylvftkf/icon_xJUf2eUS8.png'
      // }
    ];

    if (this.courierService.courier.courierSetting.referralEnabled) {
      this.desktopMenu.splice(4, 0, {
        name: 'Referrals & Rewards',
        icon: this.themeService.currentIconPack.rewardsIcon,
        link: '/main/more/refer'
      });
    }

    if (this.courierService.courier.courierSetting.isLocalDeliveryOnly) {
      this.desktopMenu = this.desktopMenu.filter(menu => {
        const hiddenMenus = ['Mailbox', 'Calculator', 'Packages'];
        return !hiddenMenus.includes(menu.name);
      });

      this.desktopMenu.splice(0, 0, {
        name: 'Delivery Request',
        link: '/main/delivery-request',
        icon: this.themeService.currentIconPack.deliveryIcon
      });
    }

    this.mobileMenu = [
      {
        name: 'Deliveries',
        link: '/main/delivery-request',
        icon: this.themeService.currentIconPack.deliveryIcon,
        hidden: true
      },
      {
        name: 'Packages',
        link: '/main/packages',
        icon: this.themeService.currentIconPack.packageIcon
      },
      {
        name: 'Mailbox',
        link: '/main/mailbox',
        icon: this.themeService.currentIconPack.mailboxIcon
      },
      {
        name: 'Calculator',
        link: '/main/calculator',
        icon: this.themeService.currentIconPack.calculatorIcon
      },
      {
        name: 'Invoices',
        link: '/main/invoices',
        icon: this.themeService.currentIconPack.invoiceIcon
      },
      {
        name: 'More',
        link: '/main/more',
        icon: this.themeService.currentIconPack.moreIcon
      }
    ];

    if (this.courierService.courier.courierSetting.isLocalDeliveryOnly) {
      this.mobileMenu = this.mobileMenu.filter(menu => {
        const hiddenMenus = ['Packages', 'Mailbox', 'Calculator'];

        return !hiddenMenus.includes(menu.name);
      });

      this.mobileMenu.splice(2, 0, {
        name: 'Profile',
        link: '/main/more/profile',
        icon: this.themeService.currentIconPack.profileIcon
      });
    } else {
      this.mobileMenu.splice(0, 1);
    }
  }
}

class NavBarItem {
  name: string;
  icon: string;
  link: string;
  hidden?: boolean;
}
